<template>
  <div class="page-wrapper" data-aos="fade-up" data-aos-duration="600">
    <div class="wpo-breadcumb-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wpo-breadcumb-wrap">
              <h2>About Us</h2>
              <ul>
                <li><router-link to="/">Home</router-link></li>
                <li><span>About</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="mission_data">
      <div class="gooey" v-if="mission_data.length === 0" style="height: 500px">
        <img src="../assets/images/Infinity-loader.gif" alt="">
      </div>

      <div class="section-padding" v-else data-aos="fade-up" data-aos-duration="600">
        <div class="container" v-if="mission_data.status === 'published'" >
          <div class="row">
            <div class="col-lg-12">
              <div class="wpo-section-title">
                <span>Our Mission</span>
                <h2>{{mission_data.mission_title}}</h2>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="default-img">
                <img :src="mission_data.mission_image" alt="">
              </div>
            </div>
            <div class="col-lg-12">
              <div class="wpo-about-text mt-50 mb-50">
                <div v-html="mission_data.mission_description"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wpo-about-video-area">
        <div class="container" v-if="mission_data.status === 'published'">
          <div class="row">
            <div class="col-lg-8 col-lg-offset-2">
              <div class="wpo-about-video-item">
                <div class="wpo-about-video-img">
                  <div class="default-img">
                    <img :src="mission_data.video_image" alt="">
                  </div>
                  <div class="entry-media video-holder">
                    <a :href="mission_data.video_url" target="_blank" class="video-btn" data-type="iframe">
                      <i class=""></i>
                    </a>
                  </div>
                </div>
                <h2>{{mission_data.video_title}}</h2>
                <div class="description-mission" v-html="mission_data.video_description"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <aboutArea :introduction="mission_data" :buttonStatus="false" v-if="mission_data.length !== 0"/>

      <counterArea :introduction="mission_data" v-if="mission_data.length !== 0 && counter"/>

      <teamArea :team="team_data" v-if="team_data.length !== 0" />
    </div>
  </div>
</template>

<script>
    import api from "../services/api";
    import counterArea from '../components/counter-area'
    import aboutArea from '../components/about-area'
    import teamArea from '../components/team-area'
    export default {
        name: "about",
        data() {
            return {
                mission_data: [],
                team_data: [],
                counter: false
            }
        },
        components: {
            counterArea,
            aboutArea,
            teamArea
        },
        async mounted() {
            window.addEventListener('scroll', this.handleScroll);
            window.scrollTo(0, 0);

            this.mission_data = await api.mission();
            this.mission_data = this.mission_data.data[0];

            this.team_data = await api.team();
            this.team_data = this.team_data.data;

            if (this.mission_data) {
                if (typeof this.mission_data.mission_image === "number") {
                    this.mission_data.mission_image = await api.getFiles(this.mission_data.mission_image);
                    this.mission_data.mission_image = this.mission_data.mission_image.data['data']['full_url'];
                }
                if (typeof this.mission_data.video_image === "number") {
                    this.mission_data.video_image = await api.getFiles(this.mission_data.video_image);
                    this.mission_data.video_image = this.mission_data.video_image['data']['data']['full_url'];
                }
            }
        },
        methods: {
            handleScroll: async function () {
                let scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
                scrollPosition     = scrollPosition + window.innerHeight;
                if (scrollPosition >= 1900) {
                    this.counter = true;
                }

            }
        }
    }
</script>

<style scoped>
  .default-img {
    width: 100%;
    background-image: linear-gradient(to right, #f725845e, #7108b778);
  }

  .default-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .gooey {
    width: 100%;
    height: 400px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .description-mission, .wpo-about-text p {
    white-space: break-spaces;
  }

  .wpo-about-video-area .row {
    border: none;
  }
</style>
